import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { useConfiguration } from '../../context/configurationContext';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    intl,
    listings,
    currentUser,
    searchInProgress,
    searchListingsError,
    pageAssetsData, inProgress, error } = props;
  const config = useConfiguration();

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      intl={intl}
      error={error}
      listings={listings}
      currentUser={currentUser}
      config={config}
      searchInProgress={searchInProgress}
      searchListingsError={searchListingsError}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    searchInProgress,
    searchListingsError,
  } = state.SearchPage;
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    currentUser,
    searchInProgress,
    searchListingsError,
    pageAssetsData, inProgress, error
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
